<!--
 * @Author: your name
 * @Date: 2021-03-02 13:58:36
 * @LastEditTime: 2024-11-20 17:15:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\login\login.vue
-->
<template>
  <div class="login-page">
    <img src="../../assets/img/logo.png" alt="" class="logo">
    <div class="title">
      你好！欢迎登录【深圳蓝生脑科医院】！
    </div>
    <van-form @submit="onSubmit">
      <label class="form-label">手机号</label>
      <van-field v-model="phoneNum" maxlength="11" placeholder="请输入手机号" />
      <div>
        <label class="form-label">验证码</label>
        <van-field
          v-model="verifyCode"
          maxlength="6"
          placeholder="请输入验证码"
        >
          <template #button>
            <Countdown
              :phone-num="phoneNum"
              @get-code-success="getCodeSuccess"
            />
          </template>
        </van-field>
      </div>
      <div class="agreement-container">
        <div>
          登录代表您已同意
          <router-link
            to="/introduce/service"
            class="blue-color"
          >
            《用户协议》
          </router-link>、
          <router-link
            to="/introduce/privacy"
            class="blue-color"
          >
            《隐私保护政策》
          </router-link>
        </div>
      </div>
      <div class="btn-box">
        <van-button
          round
          block
          native-type="submit"
          class="login-btn"
          :disabled="disabled"
        >
          登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Countdown from "../../components/countdown/Countdown"
import { userModel } from "@/api/user"
export default {
  name: "Login",
  components: {
    Countdown
  },
  data() {
    return {
      phoneNum: "",
      verifyCode: "",
      password: "",
      checked: false,
      hasGetCode: false,
      wxtoken:''
    }
  },
  computed: {
    disabled() {
      return !(this.phoneNum && this.verifyCode)
    }
  },
  mounted() { 
    this.$store.commit("user/setWxLogin", '')
  },
  methods: {
    getCodeSuccess() {
      this.hasGetCode = true
      console.log("获取验证码成功")
    },
    onSubmit() {
        if (!this.hasGetCode) {
          return this.$toast("请先获取验证码！")
        }
        this.wxToken = sessionStorage.getItem("wxToken") || ''
        let params = {
          captcha: this.verifyCode,
          phone: this.phoneNum,
          wxtoken: this.wxToken
        }
        userModel.login(params).then((res) => {
          if (res.code == 200) {
            // this.$store.commit("user/setName", nickName)
             const { token, refreshToken, phone } = res.data
            this.$store.commit("user/setPhoneNum", phone)
            this.$store.commit("user/setToken", token)
            this.$store.commit("user/setRefreshToken", refreshToken)
            sessionStorage.setItem("wxToken", '')
            //为了防止链接携带微信token，不能直接replace跳转
            // this.$router.replace("/")
            let host = window.location.protocol + "//" + window.location.host
            const url = host + "/"
            window.location.href = url
          }
        })
      }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";
.form-label {
  display: block;
  margin-top: 40px;
  font-size: 32px;
  font-weight: 400;
  color: @dark-font-color;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
  &::after {
    border-bottom: 0;
  }
}
.van-field {
  border-bottom: 1px solid #ddd;
}
.login-btn {
  width: 686px;
  height: 80px;
  font-size: 30px;
  color: #ffffff;
  background: #3a8ae5;
}
.white-btn {
  width: 686px;
  height: 80px;
  font-size: 30px;
  color: #333;
  background: #ffffff;
  margin-top: 24px;
}
.login-page {
  padding: 0 32px;
  .logo {
    width: 629px;
    height: 128px;
  }
  .title {
    margin-top: 24px;
    margin-bottom: 80px;
    font-size: 36px;
    font-weight: bold;
    color: @dark-font-color;
  }

  .agreement-container {
    position: relative;
    color: @middle-font-color;
    font-size: 24px;
    padding: 32px 0;
    .check-box {
    }
    .rightlink {
      font-size: 24px;
      color: #3d3d3d;
      position: absolute;
      top: 30px;
      right: 0;
    }
  }

  .btn-box {
    margin: 70px 0 24px;
    .disable {
      background: #b1d3fa;
    }
  }
}
</style>
